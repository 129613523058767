import React, { Component } from "react"
import { Link } from "gatsby"
import Utils from "../../utils/node.utils"

class BlogItem extends Component {
  state = {
    link: "/blog",
    title: "",
    description: "",
    thumbnail: "",
  }

  componentDidMount() {
    const { blog } = this.props
    const link = Utils.GetLanguageLink(
      blog.language,
      "/blog/" + blog.slug + "?noforward=true"
    )
    const title = Utils.GetContentKeyValue(blog.content, "title")
    const description =
      Utils.GetContentKeyValue(blog.content, "description").substring(0, 250) +
      "..."
    const thumbnail = Utils.GetContentKeyValue(blog.content, "thumbnails")

    this.setState({
      link: link,
      title: title,
      description: description,
      thumbnail: thumbnail,
    })
  }

  render() {
    const { language } = this.props
    const { link, title, description, thumbnail } = this.state

    const languageLink = Utils.GetLanguageLink(language, link)

    return (
      <div className="row d-flex align-items-top cart-item">
        <div className="col-12 col-lg-4 cart-link text-center">
          <Link to={languageLink} title={title}>
            <img
              src={thumbnail}
              alt={title}
              title={title}
              className="img-fluid"
            />
          </Link>
        </div>
        <div className="col-12 col-lg-8">
          <Link className="cart-link" to={languageLink} title={title}>
            <div className="blog-h-resize text-uppercase text-left mt-2 mt-lg-0">
              {title}
            </div>
            <p className="d-none d-lg-block text-justify">{description}</p>
          </Link>
        </div>
      </div>
    )
  }
}

export default class BlogView extends Component {
  render() {
    const { blogs, contentMap, language } = this.props

    if (!blogs) {
      return (
        <div className="container text-center mb-5">
          {Utils.GetMarkdown(contentMap.empty.value, {
            link: "text-decoration-underline",
          })}
        </div>
      )
    }

    const filteredBlogs = blogs.filter(blogHasContent)

    if (filteredBlogs.length === 0) {
      return (
        <div className="container text-center mb-5">
          {Utils.GetMarkdown(contentMap.empty.value, {
            link: "text-decoration-underline",
          })}
        </div>
      )
    }

    return (
      <div className="container mb-1 text-uppercase">
        <div className="container product-overview-text-size">
          {filteredBlogs
            .map(setTimestamps)
            .sort(sortTimestampsDesc)
            .map(item => {
              return <BlogItem key={item.id} blog={item} language={language} />
            })}
        </div>
      </div>
    )
  }
}

function setTimestamps(item) {
  const timestamp = item.content.filter(item => item.key == "timestamp")
  if (timestamp.length !== 0) {
    item.timestamp = timestamp[0].content
  }
  return item
}

function sortTimestampsAsc(a, b) {
  return sortTimestamps(a, b)
}

function sortTimestampsDesc(a, b) {
  return sortTimestamps(a, b, true)
}

function sortTimestamps(a, b, desc) {
  const aHasNoTime = !a.timestamp
  const bHasNoTime = !b.timestamp
  const equal = 0
  let lessThan
  let greaterThan

  if (desc) {
    lessThan = 1
    greaterThan = -1
  } else {
    lessThan = -1
    greaterThan = 1
  }

  if (aHasNoTime && bHasNoTime) {
    return equal
  }
  if (aHasNoTime) {
    return lessThan
  }
  if (bHasNoTime) {
    return greaterThan
  }
  if (a.timestamp == b.timestamp) {
    return equal
  }
  if (a.timestamp < b.timestamp) {
    return lessThan
  }

  return greaterThan
}

function blogHasContent(blog) {
  const content = blog.content
  if (!content || !content.length || content.length === 0) {
    return false
  }

  let hasTitle = false
  let hasDescription = false
  let hasMarkdown = false

  for (let i in content) {
    const item = content[i]

    switch (item.key) {
      case "title":
        hasTitle = true
        break
      case "description":
        hasDescription = true
        break
      case "markdown":
        hasMarkdown = true
        break
    }
  }

  return hasTitle & hasDescription & hasMarkdown
}
